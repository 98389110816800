import React, { useMemo } from "react";
import { graphql } from "gatsby";
import { Posts } from "@components";
import Layout from "@layouts";
import { filteredPosts, sortPostsByDate } from "@lib/utils";
import { useSearch } from "@hooks";

export const query = graphql`
  query Category($slug: String!) {
    category: strapiCategory(slug: { eq: $slug }) {
      name
      description
    }
    posts: allStrapiPost(filter: { category: { slug: { eq: $slug } } }) {
      totalCount
      edges {
        node {
          id
          strapiId
          title
          excerpt
          published_at
          slug
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          category {
            name
            slug
          }
          author {
            name
          }
        }
      }
    }
  }
`;

const CategoryTemplate = ({ data }) => {
  const { category, posts } = data;
  const { searchQuery } = useSearch();

  const sortedPosts = useMemo(() => sortPostsByDate(posts.edges), [posts.edges]);
  const filteredData = useMemo(() => filteredPosts(sortedPosts, searchQuery), [sortedPosts, searchQuery]);

  return (
    <Layout
      seoTitle="OPay Blog"
      banner={{
        title: "OPay Blog",
        description: category?.description,
      }}
    >
      <Posts data={filteredData} totalCount={filteredData.length} />
    </Layout>
  );
};

export default CategoryTemplate;
